import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { UpdateDetailsFormSchemas } from "../../schemas/UpdateDetailsSchemas";
import { UpdateDetailsFormTypes } from "../../types/updateDetails";

export const useMemberDetailsUpdateForm = ({
  emailAddress,
  mobileNumber,
  onSubmit,
}: {
  emailAddress: string | null;
  mobileNumber: string | null;
  onSubmit: (updatedData: UpdateDetailsFormTypes) => void;
}) => {
  const defaultValues = useMemo(() => {
    return {
      token: "",
      emailAddress: emailAddress || "",
      mobileNumber: mobileNumber || "",
      password: "",
      firstName: "",
      lastName: "",
      // birthday: "",
      region: "",
      preferredStore: "",
      IsNewsUpdatesEmailsEnabled: false,
      IsTextEnabled: false,
      IsBdcTermsAccepted: false,
      IsTermsAccepted: false,
    };
  }, [emailAddress, mobileNumber]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<UpdateDetailsFormTypes>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(UpdateDetailsFormSchemas),
    defaultValues,
    shouldFocusError: false,
  });

  const handleOnSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  // const handleSubmitError = () => {
  //   errorCanFocusSet(true);
  // };

  // useEffect(() => {
  //   reset({
  //     ...defaultValues,
  //   });
  // }, [defaultValues, reset]);

  return {
    register,
    handleOnSubmit,
    control,
    errors,
    setValue,
    reset,
    watch,
  };
};
