import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { updateMemberDetailAPI } from "../../api/members";
import { UpdateDetailsFormTypes } from "../../types/updateDetails";

const generateRandomPassword = (length: number) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const useUpdateDetails = (token: string) => {
  const navigate = useNavigate();
  const [isSuccess, isSuccessSet] = useState<boolean>(false);

  const handleSubmitUpdateDetails = useCallback(
    async (values: UpdateDetailsFormTypes) => {
      try {
        const passwordToSend = values.password || generateRandomPassword(10);

        await updateMemberDetailAPI({
          token: token,
          emailAddress: values.emailAddress,
          password: passwordToSend,
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          // birthday: values.birthdayDD + '-' + values.birthdayMM + '-1904',
          preferredStore: values.preferredStore,
          flEmail: values.IsNewsUpdatesEmailsEnabled ? 1 : 0,
          flSMS: values.IsTextEnabled ? 1 : 0,
        });
        toast.success("Member update successful!");
        isSuccessSet(true);
      } catch (error) {
        const errorResponse = JSON.parse(JSON.stringify(error));
        if (
          errorResponse.description ===
          "Phone number already exists in the program"
        ) {
          toast.error("This mobile number is already registered.");
          isSuccessSet(true);
        } else if (
          errorResponse.description ===
          "Customer is not in status that allows login with token"
        ) {
          toast.error("Account already exists.");
          isSuccessSet(true);
        } else if (errorResponse.description === "Token is already used") {
          toast.error("This account has already been registered.");
          isSuccessSet(true);
        } else if (
          errorResponse.description === "Email already exists in the program"
        ) {
          toast.error("This email is already registered.");
          isSuccessSet(true);
        } else if (errorResponse.description === "Token expired") {
          toast.error(
            "Registration token has expired. Contact Paper Plus Helpdesk."
          );
          isSuccessSet(true);
        } else {
          toast.error("Something went wrong!");
        }
      }
    },
    [updateMemberDetailAPI, isSuccessSet, toast]
  );

  useEffect(() => {
    if (isSuccess)
      setTimeout(() => {
        window.location.href = "https://www.paperplus.co.nz/";
      }, 5000);
  }, [isSuccess]);

  return {
    handleSubmitUpdateDetails,
  };
};
